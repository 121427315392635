import { Box, Heading, Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function SpecialService() {
  const navigate = useNavigate();

  useEffect(() => {
    document.addEventListener("contextmenu", (event) => event.preventDefault());

    const disableShortcuts = (e) => {
      if (e.ctrlKey && (e.key === 's' || e.key === 'S' || e.key === 'p' || e.key === 'P')) {
        e.preventDefault();
      }
    };
    window.addEventListener('keydown', disableShortcuts);

    return () => {
      window.removeEventListener('keydown', disableShortcuts);
    };
  }, []);

  const checkLogin = () => {
    const pack = sessionStorage.getItem("pack");
    if (pack) {
      return true;
    } else {
      alert("You must be logged in to watch live");
      sessionStorage.setItem("comingFrom", "/special");
      return navigate("/login");
    }
  };

  useEffect(() => {
    checkLogin();
  }, []);

  const sessions = [
    {
      sessionTitle: "DAY 1 MORNING SESSION",
      videos: [
        {
          src: "https://lwcanada.org/videos/Excelling_in_This_Grace_Pastor_Mary_Owase.mp4",
          title: "Super Session: Excelling in This Grace"
        },
        {
          src: "https://lwcanada.org/videos/Healing_Streams_Strategy_Pastor_Deola_Phillips.mp4",
          title: "Healing School Strategy Session"
        }
      ]
    },
    {
      sessionTitle: "DAY 1 EVENING SESSION",
      videos: [
        {
          src: "https://lwcanada.org/videos/Expectations_and_Previous_IPPC_Experience_Pastor_Chike_Ume.mp4",
          title: "Panel Discussion: Expectations & Previous IPPC Expectations"
        },
        {
          src: "https://lwcanada.org/videos/Masterclass_How_To_Function_as_an_Effective_Partnership_Evangelist_Or_Protagonist_Pastor_Arinze_Emmanuels.mp4",
          title: "Masterclass: How to Function as an Effective Partnership Evangelist or Protagonist"
        },
        {
          src: "https://lwcanada.org/videos/IPPC_2024_Day_1_Rev_Chris_Oyakhilome.mp4",
          title: "Plenary Session: IPPC 2024 Day 1"
        }
      ]
    },
    {
      sessionTitle: "DAY 2 MORNING SESSION",
      videos: [
        {
          src: "https://lwcanada.org/videos/Masterclass_The_Life_of_a_Spiritual_Partner_Pastor_Dupe_Isesele.mp4",
          title: "Masterclass: The Life of a Spiritual Partner"
        },
        {
          src: "https://lwcanada.org/videos/The_Resilient_Financial_Partner_in_Times_of_Global_Economic_Crisis_Pastor_Biodun_Lawal.mp4",
          title: "Super Session: The Resilient Financial Partner in Times of Global Economic Crisis"
        }
      ]
    },
    {
      sessionTitle: "DAY 2 EVENING SESSION",
      videos: [
        {
          src: "https://lwcanada.org/videos/Rhapsody_of_Realities_Strategy_Session_Pastor_Yemisi_Kudehinbu.mp4",
          title: "Rhapsody of Realities Strategy Session"
        },
        {
          src: "https://lwcanada.org/videos/My_Odyssey_in_Partnership_Part_2_Pastor_Chike_Ume.mp4",
          title: "My Odyssey in Partnership Part 2"
        }
      ]
    },
    {
      sessionTitle: "DAY 3 MORNING SESSION",
      videos: [
        {
          src: "https://lwcanada.org/videos/How_to_Use_Influence_to_Advocate_For_Kingdom_Causes_Pastor_Ifeoma_Chiemeka.mp4",
          title: "Super Session: How to Use Influence to Advocate for Kingdom Causes"
        },
        {
          src: "https://lwcanada.org/videos/My_Odyssey_in_Partnership_Part_3_Pastor_Chike_Ume.mp4",
          title: "My Odyssey in Partnership Part 3"
        },
        {
          src: "https://lwcanada.org/videos/Kingdom_Financial_Stewardship_Rev_Ken_Oyakhilome.mp4",
          title: "Super Session: Kingdom Financial Stewardship"
        }
      ]
    }
  ];


  return (
    <Box onContextMenu={(e) => e.preventDefault()}>
      <Heading>IPPC In Your City Videos</Heading>
      <Tabs isFitted variant="enclosed">
        <TabList>
          {sessions.map((session, index) => (
            <Tab key={index}>{session.sessionTitle}</Tab>
          ))}
        </TabList>

        <TabPanels>
          {sessions.map((session, index) => (
            <TabPanel key={index}>
              <Tabs isFitted variant="enclosed">
                <TabList>
                  {session.videos.map((video, vidIndex) => (
                    <Tab key={vidIndex}>{video.title}</Tab>
                  ))}
                </TabList>

                <TabPanels>
                  {session.videos.map((video, vidIndex) => (
                    <TabPanel key={vidIndex}>
                      <video
                        src={video.src}
                        controls={true}
                        width="100%"
                        height="auto"
                        controlsList="nodownload"
                      />
                    </TabPanel>
                  ))}
                </TabPanels>
              </Tabs>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Box>
  );
}
