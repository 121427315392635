import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// Import the components
import LiveService from "./pages/LiveService";
import Landing from "./pages/LandingROWD";
import Location from "./components/Location";
import Register from "./components/Register";
import Login from "./components/Login";
import PrayerSalvation from "./components/PrayerSalvation";
import AboutCE from "./components/AboutCE";
import AboutPastorChris from "./components/AboutPastorChris";
import AboutBLW from "./components/AboutBLW";
import WatchPage from "./components/Watch";
import OnDemand from "./pages/OnDemand";
import FiftyDays from "./pages/50Days";
import SoulWon from "./components/SoulWon";
import IclcRegistration from "./components/IclcRegistration";
import MayReach from "./components/MayReach";
import ForPastor from "./pages/ForPastor";

// import PandFast from "./pages/10 Days Prayer and Fasting";
import ContactComponent from "./components/ContactComponent";
import FellowshipMap from "./components/map/Map";
import SpecialService from "./components/GlobalService";
import FellowshipList from "./components/FellowshipAccordionTest";
import LandingROC from "./pages/LandingROC";
import ProgramRegistration from "./components/ProgramRegistration";
import WatchLiveCTC from "./pages/ProgramReg";
import HSLHSRegister from "./components/HSLHSRegister";
import WatchLiveHSLHS from "./pages/HSLHSView";
import URLShortenerComponent from "./pages/Shortener";
import RedirectShortenedUrl from "./components/RedirectShortenedUrl";
import LandingROWD from "./pages/LandingROWD";

export default function Paths() {
  return (
    <Routes>
      {/* <Route path="/" element={<Landing />} /> */}
      <Route path="/" element={<Navigate to="/rowd" replace />} />
      {/* <Route path="/" element={<LandingROC />} /> */}
      <Route path="/watch-live" element={<LiveService />} />
      <Route path="/on-demand" element={<OnDemand />} />
      <Route path="/register" element={<Register />} />
      <Route path="/login" element={<Login />} />
      <Route path="/locations/:code" element={<Location />} />
      <Route path="/contact/salvation" element={<PrayerSalvation />} />
      <Route path="/contact/:code" element={<ContactComponent />} />
      <Route path="/videos/:_id" element={<WatchPage />} />
      <Route path="/about/christ-embassy" element={<AboutCE />} />
      <Route path="/about/pastor-chris" element={<AboutPastorChris />} />
      <Route path="/about/blwcanada" element={<AboutBLW />} />
      <Route path="/100-days" element={<FiftyDays />} />
      <Route path="/100-days-register" element={<SoulWon />} />
      {/* <Route path="/prayer-and-fasting" element={<PandFast />} /> */}
      <Route path="/the-mandate" element={<MayReach />} />
      <Route path="/iclc-registration" element={<IclcRegistration />} />
      <Route path="/stream" element={<ForPastor />} />
      <Route path="/rowcan" element={<LandingROC />} />
      <Route path="/test" element={<HSLHSRegister />} />
      {/* <Route path="/hslhs" element={<HSLHSRegister />} /> */}
      <Route path="/fellowships" element={<FellowshipList />} />
      <Route path="/map" element={<FellowshipMap />} />
      <Route path="/special" element={<SpecialService />} />
      <Route path="/ctc" element={<ProgramRegistration />} />
      <Route path="/cnc" element={<Navigate to="/ctc" replace />} />
      <Route path="/watch-ctc" element={<WatchLiveCTC />} />
      <Route path="/hslhs" element={<WatchLiveHSLHS />} />
      <Route path="/s" element={<URLShortenerComponent />} />
      <Route path="/s/:name" element={<RedirectShortenedUrl />} />
      <Route path="rowd" element={<LandingROWD />} />
    </Routes>
  );
}
